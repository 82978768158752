<template>
  <div v-if="page_loading">
      <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />
    <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only">
      <v-card-title  style="height: 60px;">
        <h4>{{use_language.report}}</h4> <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">{{use_language.use_of_space}}</span>
      </v-card-title>
    </v-card>

    <!-- ส่วนที่ 1  -->
    <v-row justify="start" align="center" class="my-7 margin-card-status">
      
      <v-col cols="12" sm="12" md="12" lg="4" align="left">
        <v-card  dark color="teal" style="height: 175px;">
          <v-card-title>
            {{use_language.pallet}}
          </v-card-title>
          <v-divider/>
          <v-card-title style="height : 135px;">
            <v-row align="center">
              <v-col cols="1" xl="1" align="left" style="height : 110px;" class="pa-0">
                <img src="@/assets/icon_jpg/i15.jpg"  style="width: 40px" />
              </v-col>
              <v-col cols="11" xl="11" align="center" >
                <h2><span>{{ this.pallele }}</span></h2>
                <!-- <h2 v-if="this.pallele != 0"><span>{{ this.pallele }}</span></h2> -->
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="4" align="left">
        <v-card dark color="primary" style="height: 175px;" >
          <v-card-title>
            {{use_language.rack}}
          </v-card-title>
          <v-divider/>
          <v-card-title style="height : 135px;">
            <v-row align="center">
              <v-col cols="1" xl="1" align="left" style="height : 110px;" class="pa-0">
                <img src="@/assets/icon_jpg/i16.jpg"  style="width: 40px" />
              </v-col>
              <v-col cols="11" xl="11" align="center">
                <h2><span>{{ this.rack }}</span></h2>
                <!-- <h2 v-if="this.rack != 0"><span>{{ this.rack }}</span></h2> -->
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="4"  align="left">
        <v-card dark color="#E56717" style="height: 175px;" >
          <v-card-title>
            {{use_language.shelf}}
          </v-card-title>
          <v-divider/>
          <v-card-title style="height : 135px;">
            <v-row align="center">
              <v-col cols="1" xl="1" align="left" style="height : 110px;" class="pa-0">
                <img src="@/assets/icon_jpg/i17.jpg"  style="width: 40px" />
              </v-col>
              <v-col cols="11" xl="11" align="center">
                <h2><span>{{ this.shelf }}</span></h2>
                <!-- <h2 v-if="this.shelf != 0"><span>{{ this.shelf }}</span></h2> -->
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="4" align="left">
        <v-card  dark color="info" style="height: 175px;">
          <v-card-title>
            {{use_language.cold_room_pallet}}
          </v-card-title>
          <v-divider/>
          <v-card-title style="height : 135px;">
            <v-row align="center">
              <v-col cols="1" xl="1" align="left" style="height : 110px;" class="pa-0">
                <img src="@/assets/icon_jpg/i18.jpg"  style="width: 40px" />
              </v-col>
              <v-col cols="11" xl="11" align="center">
                <h2 ><span>{{ this.coolPallele }}</span></h2>
                <!-- <h2 v-if="this.coolPallele != 0"><span>{{ this.coolPallele }}</span></h2> -->
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="4" align="left">
        <v-card dark color="purple" style="height: 175px;" >
          <v-card-title>
           {{use_language.cold_rack}}
          </v-card-title>
          <v-divider/>
          <v-card-title style="height : 135px;">
            <v-row align="center">
              <v-col cols="1" xl="1" align="left" style="height : 110px;" class="pa-0">
                <img src="@/assets/icon_jpg/i19.jpg"  style="width: 40px" />
              </v-col>
              <v-col cols="11" xl="11" align="center">
                <h2><span>{{ this.coolRack }}</span></h2>
                <!-- <h2 v-if="this.coolRack != 0"><span>{{ this.coolRack }}</span></h2> -->
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="4"  align="left">
        <v-card dark color="#FBB117" style="height: 175px;" >
          <v-card-title>
            {{use_language.cold_shelf}}
          </v-card-title>
          <v-divider/>
          <v-card-title style="height : 135px;">
            <v-row align="center">
              <v-col cols="1" xl="1" align="left" style="height : 110px;" class="pa-0">
                <img src="@/assets/icon_jpg/i20.jpg"  style="width: 40px" />
              </v-col>
              <v-col cols="11" xl="11" align="center">
                <h2><span>{{ this.coolShelf }}</span></h2>
                <!-- <h2 v-if="this.coolShelf != 0"><span>{{ this.coolShelf }}</span></h2> -->
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <!-- ส่วนที่ 2 -->
    <v-card class="v-card-bottom-30 ">
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2" lg="1" class="pb-3">
             <b>{{use_language.date}} : </b>
          </v-col>
          <v-col cols="12" md="2">
            <v-menu
              ref="search_menu_date_from"
              v-model="search_menu_date_from"
              :close-on-content-click="false"
              :return-value.sync="search_date_from"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                    :value="computed_search_date_from"
                    @change="value => computed_search_date_from = value"
                    autocomplete="off"
                    label="เริ่ม"
                    outlined
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    hide-details
                    v-on="on"
                >
                  <template v-slot:label> <span>{{use_language.start_}}</span> </template> 
                </v-text-field>
              </template>
              <v-date-picker v-model="search_date_from" @input="search_menu_date_from = false, $refs.search_menu_date_from.save(search_date_from)" no-title :allowed-dates="allowedDates" class="my-0"/>
            </v-menu>
          </v-col>
          <v-col cols="12" md="2">
            <v-menu
                ref="search_menu_date_to"
                v-model="search_menu_date_to"
                :close-on-content-click="false"
                :return-value.sync="search_date_to"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      :value="computed_search_date_to"
                      @change="value => computed_search_date_to = value"
                      autocomplete="off"
                      label="สิ้นสุด"
                      outlined
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="search_date_to" @input="search_menu_date_to = false, $refs.search_menu_date_to.save(search_date_to)" no-title :allowed-dates="allowedDates" class="my-0">
                </v-date-picker>
              </v-menu>
          </v-col>
          <v-row >
            <v-col cols="12" md="6">
              <v-btn color="primary" @click="getSpaceTable(), descriptionIndex = null">ค้นหา</v-btn>
            </v-col>
            <v-col class="hidden-xs-only" cols="12" md="6" align="right">
              <v-btn size="1" color="success" dark ><v-icon >mdi-file-excel</v-icon>
                <vue-excel-xlsx
                    :data="dataSet"
                    :columns="excel_totalReportTable_header"
                      v-bind:filename='use_language.report_area_utilization'
                      v-bind:sheetname='use_language.report_area_utilization'
                    >
                   {{use_language.export_excel}}
                </vue-excel-xlsx>
              </v-btn>
            </v-col>
          </v-row>

          <v-col cols="12" >
            <div >
              <v-data-table :headers="headersAll" :items="dataSet" item-key="id" hide-default-footer :items-per-page="10000" class="elevation-0 packhai-border-table" >

                <template v-slot:item.createdDateTime="{ item }" >
                  {{ set_format_date_time(item.createdDateTime) }}
                </template>

                <template v-slot:item.pallete="{ item }" >
                  <span v-if="item.pallete!= 0">{{ item.pallete }}</span>
                </template>

                <template v-slot:item.rack="{ item }">
                  <span v-if="item.rack != 0">{{ item.rack }}</span>
                </template>

                <template v-slot:item.shelf="{ item }">
                  <span v-if="item.shelf != 0">{{ item.shelf }}</span>
                </template>

                <template v-slot:item.coolPallete="{ item }">
                  <span v-if="item.coolPallete != 0">{{ item.coolPallete }}</span>
                </template>

                <template v-slot:item.coolRack="{ item }">
                  <span v-if="item.coolRack != 0"><b>{{ item.coolRack }}</b></span>
                </template>

                <template v-slot:item.coolShelf="{ item }" >
                  <span v-if="item.coolShelf != 0">{{ item.coolShelf }}</span>
                </template>
              </v-data-table>
            </div>
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import Loading from '@/website/components/Loading'
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import { branchService_dotnet  } from '@/website/global'
  import { format_number, format_order_id, format_price, set_format_date_time,get_languages } from '@/website/global_function'
  import {  header_token } from '@/website/backend/token'

  
  export default {
    components: {
        Loading
    },
    data: () => ({

      page_loading: true,
      loading: false,

      use_language: null,
      set_language: null,

      search_menu_date_from: false,
      search_date_from: null,

      search_menu_date_to: false,
      search_date_to: null,

      footer_props: {
         'items-per-page-options': [50],
         'items-per-page-text': null,
         'disable-items-per-page': true
      },
      descriptionIndex: null,

      //shop space
      dataSet: [],
      pallele: '',
      rack: '',
      shelf: '',
      coolPallele: '',
      coolRack: '',
      coolShelf: '',
      latestDate: '',

      palleleTab: '',
      rackTab: '',
      shelfTab: '',
      coolPalleleTab: '',
      coolRackTab: '',
      coolShelfTab: '',
      dateTab: '',

      excel_totalReportTable_header : [
        { label: "วันที่", field: "date", width: 20},
        { label: "พาเลท", field: "pallete", width: 15 },
        { label: "Rack", field: "rack", width: 15 },
        { label: "ชั้นวาง", field: "shelf", width: 15 },
        { label: "พาเลทห้องเย็น", field: "coolPallete", width: 15 },
        { label: "Rack ห้องเย็น", field: "coolRack", width: 15 },
        { label: "ชั้นวางห้องเย็น", field: "coolShelf", width: 15 },
      ],

      headersAll: [
        { text: 'วันที่', align: 'center', value: 'date', sortable: false, width: '10%' },
        { text: 'พาเลท', align: 'center', value: 'pallete', sortable: false, width: '10%' },
        { text: 'Rack', align: 'center', value: 'rack', sortable: false, width: '10%' },
        { text: 'ชั้นวาง', align: 'center', value: 'shelf', sortable: false, width: '10%' },
        { text: 'พาเลทห้องเย็น', align: 'center', value: 'coolPallete', sortable: false, width: '10%' },
        { text: 'Rack ห้องเย็น', align: 'center', value: 'coolRack', sortable: false, width: '10%' },
        { text: 'ชั้นวางห้องเย็น', align: 'center', value: 'coolShelf', sortable: false, width: '10%',},
      ],

    }),
    computed: {

      computed_search_date_from() {
        return this.formatDates(this.search_date_from)
      },
      computed_search_date_to() {
        return this.formatDates(this.search_date_to)
      },
    },
    async created() {

 //เก็บภาษามาไว้ใน use_language
         this.use_language = await this.get_languages(localStorage.getItem("set_language"));


      // this.can_edit_balance_permission = JSON.parse(localStorage.getItem('permission'))['EditBalanceAgent']
      // this.topupHistory.headersAll = this.headersAll ;
      await this.getHistoryDate()
      await this.getHistorySpace()
      await this.getSpaceTable()
      this.page_loading = false
    },
    methods: {
      get_languages,
      format_number, format_order_id, format_price, set_format_date_time,
      allowedDates: val => val <= new Date().toISOString().substr(0, 10),

      // new
      formatDate (date) {
        if (date.length == 0) return null

        if (date.length == 1) {
          const [year, month, day] = date[0].split('-')
          return `${day}-${month}-${year}`
        } else {
          const [year_from, month_from, day_from] = date[0].split('-')
          const [year_to, month_to, day_to] = date[1].split('-')
          if (date[0] < date[1]) {
            return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
          } else {
            return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
          }
        }
      },

      formatDates (date) {
        if (date == null){
          return null
        } else {
          const [year, month, day] = date.split('-')
          return `${day}-${month}-${year}`
        }
      },

      showDescription (index, ID) {
        if(this.descriptionIndex == index){
          this.descriptionIndex = null
        }else {
          this.descriptionIndex = index
        }

      },

      async getHistoryDate(){
        let response = await axios.post(
            branchService_dotnet + 'Shop/shop-space-summary',
            {
                'branchID': localStorage.getItem("branch_id"),
                'shopID': localStorage.getItem("shop_id"),
                'dateFrom': this.search_date_from,
                'dateTo': this.search_date_to,
            },
            { headers: header_token }
        );
        if(response.data.dateFrom != null && response.data.dateTo != null){
          this.search_date_from = response.data.dateFrom
          this.search_date_to = response.data.dateTo
        } else {
          var nowDate = new Date()
          this.search_date_to = nowDate.toISOString().substr(0, 10)
          nowDate.setDate(nowDate.getDate()-(nowDate.getDate()-1))
          this.search_date_from = nowDate.toISOString().substr(0, 10)
        }

      },
      async getHistorySpace(){
        let response = await axios.post(
            branchService_dotnet + 'Shop/shop-space-summary',
            {
                'branchID': localStorage.getItem("branch_id"),
                'shopID': localStorage.getItem("shop_id"),
                'dateFrom': this.search_date_from,
                'dateTo': this.search_date_to,
            },
            { headers: header_token }
        );

        if(response.data.spaceSummary[0]){
          console.log(response.data);
          this.pallele = response.data.spaceSummary[0].pallele
          this.rack = response.data.spaceSummary[0].rack
          this.shelf = response.data.spaceSummary[0].shelf
          this.coolPallele = response.data.spaceSummary[0].coolPallele
          this.coolRack = response.data.spaceSummary[0].coolRack
          this.coolShelf = response.data.spaceSummary[0].coolShelf
          this.latestDate = response.data.spaceSummary[0].latestDate
        }
      },

      async getSpaceTable(){
        this.getHistorySpace()
        let response = await axios.post(
            branchService_dotnet + 'Shop/shop-space-detail-list',
            {
                'branchID': localStorage.getItem("branch_id"),
                'shopID': localStorage.getItem("shop_id"),
                'dateFrom': this.search_date_from,
                'dateTo': this.search_date_to,
            },
            { headers: header_token }
        );
          this.dataSet = response.data.shopSpaceDetailItem
      },
    }
  }
</script>
<style>
  .packhai-border-table{
    /* border:1px solid #e9edf4; */
    border-collapse: collapse;
    border-spacing: 0px;
    border-right: 1px solid #e9edf4;
    border-bottom: 1px solid #e9edf4;
    border-top: 1px solid #e9edf4;
  }
  .packhai-border-table thead{
    color:white;
  }
  .packhai-border-table thead tr th{
    color: black !important;
    font-weight: bold;
    font-size: 14px;
  }
  .packhai-border-table tr {
    /* border-collapse: collapse; */
    /* border: 1px solid #e9edf4; */
  }
  .packhai-border-table th {
    /* border-collapse: collapse; */
      border-left: 1px solid #e9edf4;
  }
  .packhai-border-table tr td{
    border-left: 1px solid #e9edf4;
  }

</style>
