<template>
  <div v-if="page_loading">
      <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />
    <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only">
      <v-card-title  style="height: 60px;">
        <h4>{{use_language.report}}</h4> <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">{{use_language.sales}}</span>
      </v-card-title>
    </v-card>

    <v-row class="v-card-bottom-30">
      <v-col cols="12" md="6" lg="6">
        <v-card style="min-height: 460px; display: flex; flex-wrap: wrap; align-content: center;" @click="toReports(1, '#order')">
          <v-card-text align="center">
            <v-row>
              <v-col><h2>{{use_language.today_sales}}</h2></v-col>
            </v-row>
            <v-row>
              <v-col>
                <h2 class="text-primary" v-if="this.ReportCaed1['CountOrderID'] != null">{{ this.ReportCaed1['CountOrderID'] }}</h2>
                <h2 class="text-primary" v-else>0</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col><h3>{{use_language.order}}</h3></v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3>{{use_language.elite}}
                  <span class="text-success" v-if="this.ReportCaed1['TotalPrices'] != null">{{ format_price( this.ReportCaed1['TotalPrices']) }}</span>
                  <span class="text-success" v-else>0</span>
                   {{use_language.bath}}
                </h3></v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <v-card style="min-height: 460px" align="center" @click="toReports(1, '#top_sales')">
          <v-card-text>
            <v-row>
              <v-col><h2>{{use_language.best_selling_products_today}}</h2></v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div >
                  <v-data-table :headers="header" :items="ReportDataTop5" hide-default-footer :items-per-page="10000" class="elevation-0 packhai-border-table" >
                    <template v-slot:item.TotalQuantitys="{ item }">
                      <font>{{item.TotalQuantitys}}</font>
                    </template>
                  </v-data-table>
                </div>
              </v-col>
              <v-col cols="12" align="right">
                <v-btn outlined color="success">
                  {{use_language.see_more_details}}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <v-card style="min-height: 460px; display: flex; flex-wrap: wrap; align-content: center;" @click="toReports(2, '#order')">
          <v-card-text align="center" >
            <v-row>
              <v-col><h2>{{use_language.sales}} ({{ this.formatDates(this.date_from_last_week.toString()) }} - {{ this.formatDates(this.date_to_last_week.toString()) }})</h2></v-col>
            </v-row>
            <v-row>
              <v-col>
                <h2 class="text-primary" v-if="this.ReportCaed1L['CountOrderID'] != null">{{ this.ReportCaed1L['CountOrderID'] }}</h2>
                <h2 class="text-primary" v-else>0</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col><h3>{{use_language.order}}</h3></v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3>{{use_language.elite}}
                <span class="text-success" v-if="this.ReportCaed1L['TotalPrices'] != null">{{ format_price( this.ReportCaed1L['TotalPrices']) }}</span>
                <span class="text-success" v-else>0</span> {{use_language.bath}}
              </h3>
            </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <v-card style="min-height: 460px" align="center" @click="toReports(2, '#top_sales')">
          <v-card-text>
            <v-row>
              <v-col><h2>{{use_language.best_seller}}  ({{ this.formatDates(this.date_from_last_week.toString()) }} - {{ this.formatDates(this.date_to_last_week.toString()) }})</h2></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" >
                <div>
                  <v-data-table :headers="header" :items="ReportDataTop5L" hide-default-footer :items-per-page="10000" class="elevation-0 packhai-border-table" >
                    <template v-slot:item.TotalQuantitys="{ item }">
                      <font>{{item.TotalQuantitys}}</font>
                    </template>
                  </v-data-table>
                </div>
              </v-col>
              <v-col cols="12" align="right">
                <v-btn outlined color="success">
                  {{use_language.see_more_details}}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <v-card style="min-height: 460px; display: flex; flex-wrap: wrap; align-content: center;" @click="toReports(3)">
          <v-card-text align="center">
            <v-row>
              <v-col><h2>{{use_language.customer_type}}</h2></v-col>
            </v-row>
             <v-row>
               <v-col><h3>{{use_language.date_information}} ({{ this.formatDates(this.date_from_last_week.toString()) }} - {{ this.formatDates(this.date_to_last_week.toString()) }})</h3></v-col>
             </v-row>
            <v-row>
              <v-col><h3>{{use_language.old_customer}}</h3></v-col>
            </v-row>
            <v-row>
              <v-col><h3 class="text-primary">{{ this.Percents[0] }}%</h3></v-col>
            </v-row>
            <v-row>
              <v-col><h3>{{use_language.new_customer}}</h3></v-col>
            </v-row>
            <v-row>
              <v-col><h3 class="text-success">{{ this.Percents[1] }}%</h3></v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" md="6" lg="6">
        <v-card style="min-height: 460px" @click="toReports(4)">
          <v-card-text>
            card6
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>

    <!-- <v-card class="v-card-bottom-30">
      <v-card-text v-if="this.ReportData.length > 0">
        <v-data-table :headers="header" :items="ReportData" hide-default-footer :items-per-page="10000" class="elevation-0 packhai-border-table" >
          <template v-slot:item.TotalQuantitys="{ item }">
            <font>{{item.TotalQuantitys}}</font>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card> -->
  </div>
</template>

<script>
import Loading from '@/website/components/Loading'
import axios from 'axios'
import { reportService } from '@/website/global'
import { format_number, format_order_id, format_price ,get_languages} from '@/website/global_function'
 
  export default {
    components: {
        Loading
    },
    data: () => ({
      page_loading: true,
      loading: false,
      use_language: null,
      set_language: null,
      search_menu_date_from: false,
      search_date_from: null,

      search_menu_date_to: false,
      search_date_to: null,

      date_from: null,
      date_to: null,

      date_from_last_week: null,
      date_to_last_week: null,

      header: [
        { text: 'ลำดับ', align: 'left', value: 'No', sortable: false, width: '5%' },
        { text: 'สินค้า', align: 'left', value: 'StockName', sortable: false, width: '45%' },
        { text: 'จำนวน', align: 'right', value: 'TotalQuantitys', sortable: false, width: '50%' },
      ],

      ReportData: [],
      ReportCaed1: [],
      ReportDataTop5: [],

      ReportCaed1L: [],
      ReportDataTop5L: [],
      Percents: [],

      shop_id: null,



    }),
    computed: {

      computed_search_date_from() {
        return this.formatDates(this.search_date_from)
      },
      computed_search_date_to() {
        return this.formatDates(this.search_date_to)
      },
    },
    watch: {

    },
    async created() {

      //เก็บภาษามาไว้ใน use_language
         this.use_language = await this.get_languages(localStorage.getItem("set_language"));


      this.shop_id = localStorage.getItem("shop_id")
      var nowd = new Date()
      this.date_from = [nowd.getFullYear().toString()+'-'+ this.setZero(nowd.getMonth()+1).toString()+'-'+ this.setZero(nowd.getDate())]
      this.date_to = [nowd.getFullYear().toString()+'-'+ this.setZero(nowd.getMonth()+1).toString()+'-'+ this.setZero(nowd.getDate())]

      var date_tmp = nowd
      var day_tmp = date_tmp.getDay()

      // ลบวันที่ในรูปแบบ datetime แล้วค่อยแปลงกลับเป็น string
      var lastWeek = new Date(date_tmp.setDate(date_tmp.getDate()-day_tmp))
      var lastWeek7 = new Date(date_tmp.setDate(date_tmp.getDate()-(6)))

      this.date_to_last_week = [lastWeek.getFullYear().toString()+'-'+ this.setZero(lastWeek.getMonth()+1).toString()+'-'+ this.setZero(lastWeek.getDate())]
      this.date_from_last_week = [lastWeek7.getFullYear().toString()+'-'+ this.setZero(lastWeek7.getMonth()+1).toString()+'-'+ this.setZero(lastWeek7.getDate())]

      await this.getReportSales()
      //
      this.page_loading = false
    },
    methods: {
       get_languages,
      format_number, format_order_id, format_price,
      allowedDates: val => val <= new Date().toISOString().substr(0, 10),

      formatDates (date) {
        
        if (date == null){
          return null
        } else {
          const [year, month, day] = date.split('-')
          return `${day}-${month}-${year}`
        }
      },

      async getReportSales () {
          this.loading = true

          if (this.date_from != null && this.date_to != null) {

            this.search_date_range = [
              this.date_from.toString(),
              this.date_to.toString()
            ]
            this.search_date_range_last_week = [
              this.date_from_last_week.toString(),
              this.date_to_last_week.toString()
            ]
          } else {
            this.search_date_range= []
            this.search_date_range_last_week= []
          }
          let response = await axios.post(reportService+'CODReport/getReportSales', {
              'shop_id':this.shop_id,
              'search_date_range': this.search_date_range,
              'search_date_range_last_week': this.search_date_range_last_week
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+localStorage.getItem("authorization_token")
            }
          })
          if (response.data.status == 'Success') {
            if(response.data.authorized == 0){
              this.loading = false
              this.$swal({
                type: 'warning',
                title: 'คุณไม่มีสิทธิเข้าถึงหน้านี้ !!',
              })
              this.$router.push('/order')
            } else {
              if(response.data.resultDataTableL.length > 0) {
                this.ReportData = response.data.resultDataTable

                // Card1
                this.ReportCaed1 = response.data.resultCard1
                this.ReportCaed1L = response.data.resultCard1L

                // top 5 อันดับแรก
                const lsittop5 = response.data.resultDataTable
                this.ReportDataTop5 = lsittop5.filter(lsittop5 => lsittop5['No'] < 6)

                const lsittop5L = response.data.resultDataTableL
                this.ReportDataTop5L = lsittop5L.filter(lsittop5L => lsittop5L['No'] < 6)
              }

              this.Percents = response.data.Percents

              if(response.data.resultDate != null){
                if (response.data.resultDate.length == 0) {
                  var nowDate = new Date()
                  this.search_date_to = nowDate.toISOString().substr(0, 10)
                  nowDate.setDate(nowDate.getDate()-7)
                  this.search_date_from = nowDate.toISOString().substr(0, 10)
                } else {
                  this.search_date_from = response.data.resultDate[0]
                  this.search_date_to = response.data.resultDate[1]
                }
              }
              this.loading = false
            }
          } else {
            this.$swal({
              type: 'warning',
              title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
            })
            this.loading = false
          }
          this.loading = false
      },

      SearchingClearData () {
        var nowDate = new Date()
        this.search_date_to = nowDate.toISOString().substr(0, 10)
        nowDate.setDate(nowDate.getDate()-7)
        this.search_date_from = nowDate.toISOString().substr(0, 10)
      },

      setZero (data) {
        if  (parseInt(data) < 10 ) {
          return '0'+ data
        } else {
          return data
        }
      },

      toReports(param, IsTarget) {
        var nowd = new Date()
        this.date_from = [nowd.getFullYear().toString()+'-'+ this.setZero(nowd.getMonth()+1).toString()+'-'+ this.setZero(nowd.getDate())]
        this.date_to = [nowd.getFullYear().toString()+'-'+ this.setZero(nowd.getMonth()+1).toString()+'-'+ this.setZero(nowd.getDate())]

        var date_tmp = nowd
        var day_tmp = date_tmp.getDay()

        var lastWeek = new Date(date_tmp.setDate(date_tmp.getDate()-day_tmp))
        var lastWeek7 = new Date(date_tmp.setDate(date_tmp.getDate()-(6)))

        this.date_to_last_week = [lastWeek.getFullYear().toString()+'-'+ this.setZero(lastWeek.getMonth()+1).toString()+'-'+ this.setZero(lastWeek.getDate())]
        this.date_from_last_week = [lastWeek7.getFullYear().toString()+'-'+ this.setZero(lastWeek7.getMonth()+1).toString()+'-'+ this.setZero(lastWeek7.getDate())]

        if(param == 1){
          localStorage.setItem("IsToday", '1')
          localStorage.setItem("IsTarget", IsTarget)
          localStorage.setItem("date_from", this.date_from )
          localStorage.setItem("date_to", this.date_to )
          this.$router.push('/report-sales1')
        } else if (param == 2) {
          localStorage.setItem("IsToday", '2')
          localStorage.setItem("IsTarget", IsTarget)
          localStorage.setItem("date_from", this.date_from_last_week )
          localStorage.setItem("date_to", this.date_to_last_week )
          this.$router.push('/report-sales1')
        }

      },
    }
  }
</script>
<style>
  .packhai-border-table{
    /* border:1px solid #e9edf4; */
    border-collapse: collapse;
    border-spacing: 0px;
    border-right: 1px solid #e9edf4;
    border-bottom: 1px solid #e9edf4;
    border-top: 1px solid #e9edf4;
  }
  .packhai-border-table thead{
    color:white;
  }
  .packhai-border-table thead tr th{
    color: black !important;
    font-weight: bold;
    font-size: 14px;
  }
  .packhai-border-table tr {
    /* border-collapse: collapse; */
    /* border: 1px solid #e9edf4; */
  }
  .packhai-border-table th {
    /* border-collapse: collapse; */
      border-left: 1px solid #e9edf4;
  }
  .packhai-border-table tr td{
    border-left: 1px solid #e9edf4;
  }


</style>
